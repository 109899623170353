import React, { Component } from "react";
import { FiBox, FiChevronRight } from "react-icons/fi";

class AboutSix extends Component {
  render() {
    let title =
        "Custom Software Development: Creating tailored solutions for your unique business needs.",
      description =
        "At Dinosys Infotech, we specialize in developing custom software that is designed to meet your specific requirements. Our team of experienced developers will work closely with you to understand your needs and create a solution that is tailored to your unique challenges and goals. Whether you need a new software application or want to enhance an existing one, we have the expertise to deliver high-quality, scalable, and secure solutions. Contact us today to discuss your custom software development needs.",
      icon = <FiBox />;
    return (
      <React.Fragment>
        <div className="about-wrapper">
          <div className="container">
            <div className="row row--35 align-items-center">
              <div className="col-lg-12 col-md-12">
                <div className="about-inner  align-items-center justify-content-center text-lg-center inner">
                  <div className="section-title">
                    <h3 className="title">{title}</h3>
                    <p className="description">{description}</p>
                  </div>
                </div>
              </div>
             
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}
export default AboutSix;
