// React Required
import React, { Component } from "react";
import ReactDOM from "react-dom";

// Create Import File
import "./index.scss";

import PageScrollTop from "./component/PageScrollTop";

// Home layout
// import Demo from "./page-demo/Demo";
// import MainDemo from "./home/MainDemo";
// import Startup from "./home/Startup";
// import Paralax from "./home/Paralax";
// import HomePortfolio from "./home/HomePortfolio";
// import DigitalAgency from "./home/DigitalAgency";
// import CreativeAgency from "./home/CreativeAgency";
// import PersonalPortfolio from "./home/PersonalPortfolio";
// import Business from "./home/Business";
// import StudioAgency from "./home/StudioAgency";
// import PortfolioLanding from "./home/PortfolioLanding";
// import CreativeLanding from "./home/CreativeLanding";
// import HomeParticles from "./home/HomeParticles";
// import CreativePortfolio from "./home/CreativePortfolio";
// import DesignerPortfolio from "./home/DesignerPortfolio";
// import InteriorLanding from "./home/Interior";
import CorporateBusiness from "./elements/CorporateBusiness";
// import InteractiveAgency from "./home/InteractiveAgency";

// Dark Home Layout
// import DarkMainDemo from "./dark/MainDemo";
// import DarkPortfolioLanding from "./dark/PortfolioLanding";

// Element Layout
import Service from "./elements/Service";
import ServiceDetails from "./elements/ServiceDetails";
import About from "./elements/About";
import error404 from "./elements/error404";
// import Contact from "./elements/Contact";
// import PortfolioDetails from "./elements/PortfolioDetails";
// import Blog from "./elements/Blog";
// import BlogDetails from "./elements/BlogDetails";

// Blocks Layout

import Team from "./blocks/Team";
import VideoPopup from "./blocks/VideoPopup";
import Products from "./blocks/Products";
import { BrowserRouter, Switch, Route } from "react-router-dom";
import * as serviceWorker from "./serviceWorker";
import Contact from "./elements/Contact";

class Root extends Component {
  render() {
    return (
      <BrowserRouter basename={"/"}>
        <PageScrollTop>
          <Switch>
            <Route
              exact
              path={`${process.env.PUBLIC_URL}/`}
              component={CorporateBusiness}
            />
            <Route
              exact
              path={`${process.env.PUBLIC_URL}/about-us`}
              component={About}
            />
            <Route
              exact
              path={`${process.env.PUBLIC_URL}/contact`}
              component={Contact}
            />
            <Route
              exact
              path={`${process.env.PUBLIC_URL}/service`}
              component={Service}
            />
            <Route
              exact
              path={`${process.env.PUBLIC_URL}/products`}
              component={Products}
            />
            <Route
              exact
              path={`${process.env.PUBLIC_URL}/crime-investigation-training`}
              component={Team}
            />
            <Route
              exact
              path={`${process.env.PUBLIC_URL}/Product-blog`}
              component={ServiceDetails}
            />

            <Route
              exact
              path={`${process.env.PUBLIC_URL}/blog-post`}
              component={VideoPopup}
            />

            <Route
              path={`${process.env.PUBLIC_URL}/404`}
              component={error404}
            />

            <Route component={error404} />
          </Switch>
        </PageScrollTop>
      </BrowserRouter>
    );
  }
}

ReactDOM.render(<Root />, document.getElementById("root"));
serviceWorker.register();
